.App {
  margin: 24px;
}

.App-header {
  min-height: 100vh;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  left: 0;
  right: 0;
  margin: auto;
}

